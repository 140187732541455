import fees from "@/api/fees/index";

const actions = {
  async getUserIncentives() {
    try {
      return await fees.getUserIncentives();
    } catch (error) {
      throw new Error(error.message);
    }
  },
};

export default {
  namespaced: true,
  actions,
};
