export const checkLocationUrl = "/User/checkLocation";
export const loginUrl = "/User/authenticate";
export const logoutUrl = "/User/logout";
export const sendResetMailUrl = "/User/SendResetMail";
export const checkResetTokenUrl = "/User/CheckResetToken";
export const passwordResetUrl = "/User/PasswordReset";

export const getCurrentRegistrationUrl = "/Portal/api/GetCurrentRegistrations";
export const getResearchesUrl = "/Portal/api/GetResearches";
export const GetResearchOptionsUrl = "/portal/api/GetResearchOptionsLink";
export const getScheduledAppointmentsUrl =
  "/Portal/api/GetScheduledAppointments";
// export const getIncentiveHistoryUrl = "/Portal/api/GetIncentiveHistory";
export const getUserIncentivesUrl = "/Portal/api/GetUserIncentives";

export const updateEmailRequestUrl = "/Portal/api/SendEmailChangeRequest";
export const updateEmailUrl = "/Portal/api/UpdateEmail";
export const updateNumberUrl = "/Portal/api/UpdateTelephoneNumbers";
export const updatePasswordUrl = "/Portal/api/UpdatePassword";
export const getUnsubscribeLinkUrl = "/Portal/api/GetUnsubscribeLink";
export const getProfileBlocksUrl = "/Portal/api/GetProfileBlocks";
export const getUserProfileUrl = "/Portal/api/GetUserProfile";
