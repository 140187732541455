export default {
  getUserIncentives(incentives) {
    let response = {};
    response = {
      deelnameVergoeding: incentives.deelnameVergoeding,
      reiskosten: incentives.reiskosten,
      goededoel: incentives.goededoel,
    };
    return response;
  },
};
