import client from "../client";
import { getUserIncentivesUrl } from "../url";
import res from "./response";

export default {
  async getUserIncentives() {
    return res.getUserIncentives(
      await client.withAuth().post(getUserIncentivesUrl)
    );
  },
};
